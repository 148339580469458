
/*
    First let's define a call back function which will be called when Recaptcha will be loaded.
    It means that is ready to use and we can notify Elm app.
 */

function onLoadRecaptcha (app) {
  window.onLoadRecaptchaCallback = function () {
    app.ports.onLoadRecaptcha.send(null)
  }

  // This is called when user successfully completes it's challenge
  window.onRecaptchaSuccess = function (token) {
    app.ports.onRecaptchaSuccess.send(token)
  }
  // This is called when recaptcha expires
  window.onRecaptchaExpired = function () {
    app.ports.onRecaptchaReset.send(null)
  }

  // This is called when recaptcha encounters error
  window.onRecaptchaError = function (error) {
    app.ports.onRecaptchaError.send(error.toString())
  }
}

/*
    This actually loads the recaptcha itself. It is better do it dynamically.
    And not to complicate index.html.
 */
function loadRecaptcha (lang) {
  const script = document.createElement('script')
  script.src = 'https://www.google.com/recaptcha/api.js?onload=onLoadRecaptchaCallback&render=explicit&hl=' + lang
  document.head.appendChild(script)
}

function resetRecaptcha (app) {
  if (window.grecaptcha !== undefined) {
    window.grecaptcha.reset()
    app.ports.onRecaptchaReset.send(null)
  }
}

function renderRecaptcha (app, rec) {
  const el = document.getElementById(rec.id)
  if (typeof (el) !== 'undefined' && el != null) {
    try {
      if (!el.firstChild) {
        window.grecaptcha.render(el, {
          sitekey: rec.siteKey,
          theme: 'light',
          callback: window.onRecaptchaSuccess,
          'expired-callback': window.onRecaptchaExpired
        })
        app.ports.onRecaptchaReset.send(null)
      }
    } catch (error) {
      window.onRecaptchaError(error)
    }
  }
}

export { loadRecaptcha, onLoadRecaptcha, renderRecaptcha, resetRecaptcha }
