/* Fires localtionchange event on URL change */
function createNewEvent (eventName) {
  let event
  if (typeof (Event) === 'function') {
    event = new window.Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

window.history.pushState = (function (f) {
  return function pushState () {
    const ret = f.apply(this, arguments)
    window.dispatchEvent(createNewEvent('pushstate'))
    window.dispatchEvent(createNewEvent('locationchange'))
    return ret
  }
}(window.history.pushState))

window.history.replaceState = (function (f) {
  return function replaceState () {
    const ret = f.apply(this, arguments)
    window.dispatchEvent(createNewEvent('replacestate'))
    window.dispatchEvent(createNewEvent('locationchange'))
    return ret
  }
}(window.history.replaceState))

window.addEventListener('popstate', function () {
  window.dispatchEvent(createNewEvent('locationchange'))
})

// ELM FUNCTIONS

function replaceUrl (url) {
  window.history.replaceState({}, '', url)
}

function pushUrl (url) {
  window.history.pushState({}, '', url)
}

function back (n) {
  (-1 * n) && window.history.go(-n)
}

function onUrlChange (app, isIE) {
  window.addEventListener('locationchange', function (e) {
    app.ports.onUrlChange.send(window.location.href)
  })
  if (isIE) {
    window.addEventListener('hashchange', function (e) {
      app.ports.onUrlChange.send(window.location.href)
    })
  }
}

export { replaceUrl, pushUrl, back, onUrlChange }
