/*
    There's no easy way to prevent tabbing through the background elements while dialog is open in elm-ui
    therefore we need a port that will do it for us
 */
function disableBackgroundFocus () {
  // make sure dialog is already visible
  window.requestAnimationFrame(function () {
    // setup a selector that'll allow us to tab in the modal still
    const focusableElements =
            '[tabindex]:not([tabindex="-1"])'

    // get the dialog by class
    const modal = document.querySelector('.visible-dialog')
    if (!modal) { return }

    // get first element to be focused inside dialog
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]
    // get all things that can be focused
    const focusableContent = modal.querySelectorAll(focusableElements)
    // get last element to be focused inside dialog
    const lastFocusableElement = focusableContent[focusableContent.length - 1]

    document.addEventListener('keydown', function (e) {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9

      if (!isTabPressed) {
        return
      }

      // you can tab back with shift+tab, we want to prevent that too
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus() // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        if (document.activeElement === lastFocusableElement) {
          // add focus for the first focusable element
          firstFocusableElement.focus()
          e.preventDefault()
        }
      }
    })

    firstFocusableElement.focus()
  })
}

export { disableBackgroundFocus }
