/*
  Adds event listener for page refreshes. Call this function when you want to display warning
  if user tries to leave or refresh a page.
 */

function addPageRefresh () {
  window.onbeforeunload =
        function (e) {
          e.preventDefault()
          e.returnValue = ''
        }
}

/* Removes page refresh listener. Call this function when you want to removed warning
  if user tries to leave or refresh a page.
 */
function removePageRefresh (url) {
  window.onbeforeunload = null
  if (url !== '') {
    window.location.href = url
  }
}

/*
    Changes page title.
 */
function setPageTitle (title) {
  window.document.title = title
}

export { setPageTitle, addPageRefresh, removePageRefresh }
