/*
 Prevents default browser behaviour on key press. This port should only be used in rare cases, e.g.
 if you wish to implement custom dropdown search etc.
  */
function preventKeyDefault (app) {
  window.onkeydown = function (e) {
    if ([9, 40, 38].indexOf(e.keyCode) !== -1) {
      e.preventDefault()
    }
    app.ports.keyDown.send(e.key)
  }
}

function enableKeyDefault (app) {
  window.onkeydown = function (e) {
    app.ports.keyDown.send(e.key)
  }
}

export { preventKeyDefault, enableKeyDefault }
