
/*
  Initially  we detect browser's language, so that we could auto preselect for the user.
 */
function userLanguage () {
  const lang = navigator.language || navigator.userLanguage
  return (lang.split('-')[0] || '').toLowerCase()
}

export { userLanguage }
